<!-- 管控人员列表 -->
<template>
  <div>
    <div v-if="!isShowDetail">
      <el-col :span="22">
        <el-input style="width:220px;margin-right:20px" v-model="cx.searchValue" placeholder="居民姓名、证件号码"></el-input>
        <el-select style="width:230px;margin-right:20px" v-model="cx.crowdTypeId" clearable placeholder="人群分类">
          <el-option v-for="item in typepopSelect" :key="item.id" :label="item.ruleName" :value="item.id"></el-option>
        </el-select>
        <el-input style="width:220px;margin-right:20px" v-model="cx.currentAddress" placeholder="居家地址"></el-input>
        <el-date-picker v-model="timetime" size="medium" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" />
        <el-select style="width:140px;margin-right:20px" v-model="cx.inspectStatus" clearable placeholder="隔离状态">
          <el-option v-for="item in sqsjoptionscx" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select style="width:140px;margin-right:20px" v-model="cx.controlOrgCode" filterable clearable placeholder="负责机构">
          <el-option v-for="item in xqsqsjoptionscx" :key="item.orgCode" :label="item.orgName" :value="item.orgCode">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="2" style="text-align:right">
        <el-button type="primary" @click="queryTask('btn')">查询</el-button>
      </el-col>
      <div class="top_table">
        <el-table :data="tableData02" style="width: 100%" height="780">
          <el-table-column fixed="left" label="操作" width="150">
            <template slot-scope="scope">
              <el-button @click="distribute02(scope.row)" type="text" size="small">
                解除隔离</el-button>
              <el-button @click="qxaaaa(scope.row)" type="text" size="small">查看更多
              </el-button>

            </template>
          </el-table-column>
          <el-table-column prop="name" label="居民姓名"></el-table-column>
          <el-table-column prop="age" label="年龄" width=""></el-table-column>
          <el-table-column prop="sex" label="性别" width="">
            <template slot-scope="scope">
              <span>{{ scope.row.sex | sex }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cardType" label="证件类型" width="100">
            <template slot-scope="scope">{{
                scope.row.cardType | dictFilter2(cardTypeDict)
            }}</template>
          </el-table-column>
          <el-table-column prop="cardNo" label="证件号码" width="180"></el-table-column>
          <el-table-column prop="linkPhone" label="联系号码" width="180"></el-table-column>
          <el-table-column prop="currentAddress" label="居住地址" width="180">
            <template  slot-scope="scope">
              <span  v-if="scope.row.currentAddress !=null" >{{  scope.row.currentAddress}}</span>
              <span  v-if="scope.row.currentAddressDetail !=null" >{{  scope.row.currentAddressDetail}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="crowdTypeName" label="人群分类" width="180"></el-table-column>

          <el-table-column prop="controlMethod" label="管控方式" width="120">
            <template slot-scope="scope">
              <span style="background-color: #409eff;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod == 1">{{
                  scope.row.controlMethod |
                  typerw
              }}</span>
              <span style="background-color: #f56c6c;color: #fff;padding: 0px 10px;border-radius: 10px; float: left" v-if="scope.row.controlMethod != 1">{{
                  scope.row.controlMethod | typerw
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="controlOrgName" label="管控社区" width="150"></el-table-column>

          <el-table-column prop="startTime" label="开始时间" width="160"></el-table-column>
          <el-table-column prop="endTime" label="预计解除时间" width="160"></el-table-column>
          <el-table-column prop="transferTime" label="解除时间" width="160"></el-table-column>
          <el-table-column prop="transferType" label="转出类型" width="140">
            <template slot-scope="scope">
              <span>{{ scope.row.transferType | transferType }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="transferDestination" label="转出目的地" width="140"></el-table-column>
          <el-table-column prop="transferRemark" label="转出备注" width="140"></el-table-column>
        </el-table>
        <div class="content_page">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNumber" :total="total" :page-sizes="[10, 20, 40, 60]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" />
        </div>
      </div>
      <!-- 解除隔离 -->
      <el-dialog title="解除隔离" :visible.sync="isjcgl" width="50%">
        <div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="转出类型" prop="transferType">
              <el-radio-group @change="trasHome()" v-model="ruleForm.transferType">
                <el-radio label="1">解除管控</el-radio>
                <el-radio label="2">送医治疗</el-radio>
                <el-radio label="3" :disabled="this.unselect==='3'">送往隔离点</el-radio>
                <el-radio :disabled="this.unselect === '4'" label="4">居家隔离</el-radio>
              <el-radio :disabled="this.unselect === '5'" label="5">居家监测</el-radio>
                <el-radio label="9">其他</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- 动态加载提示语 -->
            <P style="color:red; margin-bottom: 20px;margin-left:100px" v-if="this.tipsList.length>0&&this.ruleForm.transferType !== ''">
            {{this.tipsList.find(item =>{
                  return item.transferType === this.ruleForm.transferType
                }) ? this.tipsList.find(item =>{
                  return item.transferType === this.ruleForm.transferType
                }).tips : ''}}
            </P>

            <el-form-item label="转出目的地" prop="transferDestination">
              <el-input v-if="ruleForm.transferType==''||ruleForm.transferType==null" placeholder="请输入" />
              <el-input v-if="ruleForm.transferType == 1||ruleForm.transferType ==2||ruleForm.transferType ==9" style="width:90%" v-model="ruleForm.transferDestination" placeholder="请输入" />
              <el-select v-if="ruleForm.transferType == 4||ruleForm.transferType ==5" clearable filterable size="medium" v-model="homeitem" @change="homeOrgChange($event)" placeholder="负责机构">
                    <el-option v-for="(item,index) in atHomeList" :key="index" :label="item.orgName" :value="item.orgCode" />
              </el-select>

              <el-select v-if="ruleForm.transferType == 3" clearable filterable size="medium" v-model="isolationOrgItem"
              @change="isolationOrgChange($event)" placeholder="隔离点">
              <el-option v-for="(item,index) in isolationOrg" :key="index" :label="item.orgName" :value="item.orgCode" />
              </el-select>

            </el-form-item>

            <el-form-item label="备注">
              <el-input v-model="ruleForm.transferRemark"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isjcgl = false">取 消</el-button>
          <el-button type="primary" @click="relive">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 居民详情 -->
    <div v-if="isShowDetail">
      <residentInformation ref="residentInformationRef" />
    </div>
  </div>
</template>

<script>
import residentInformation from "@/views/QuarantineRegistration/residentInformation";
import constants from "@/common/constants";
import { mapState } from "vuex";
import {
  reliveControlTips
} from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { getBusinessOrgList,getAllIsolationOrgList} from "../../api/SystemManagement/index.js"; //根据业务状态查询机构
import {
  relive,
  gklist
} from "@/api/Taskmanagement/upcomingTasks.js";

export default {
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      select:"",
      homeitem:"", //选择的居家机构
      isolationOrgItem:"", //隔离机构的
      isolationOrg:[], //获取所有隔离酒店机构列表
      atHomeList:[], //居家的机构
      tipsList:[], //提示语
      unselect:'', //不能被选中的
      tableData02: [], 
      id: "",
      timetime: [],
      ruleForm: {
        transferDestination:"", //转出机构名称
        transferDestinationCode:"", //转出机构编码
        transferType: "", //转出类型
        transferRemark:"" //备注
      },
      cx: {
        inspectStatus: '1',
        crowdTypeId: '',
      },
      pageNumber: 1,
      pageSize: 10,
      isShowDetail: false,
      isjjjc: false,
      isjjgl: false,
      total: 0,
      sqoptions: "",
      sqsjoptionscx: [
        { label: '全部', value: '0' },
        { label: '隔离中', value: '1' }
        ,
        { label: '解除隔离', value: '9' }

      ],
      xqsqsjoptionscx: [],
      isjcgl: false,
      rules: {
        transferDestination: [
          { required: true, message: '请输入转出目的地', trigger: 'blur' },
        ],
        transferType: [
          { required: true, message: '请选择转出类型', trigger: 'blur' },
        ],
      },
    };
  },
  filters: {
    sex(val) {
      if (val == 1) {
        val = "男";
        return val;
      } else {
        val = "女";
        return val;
      }
    },
    typerw(val) {
      if (val == 1) {
        val = "居家监测";
        return val;
      } else {
        val = "居家隔离";
        return val;
      }
    },
    transferType(val) {
      if (val == 1) {
        val = "解除管控";
        return val;
      } else if (val == 2) {
        val = "送医治疗";
        return val;
      } else if (val == 3) {
        val = "送往隔离点";
        return val;
      } else if (val == 4) {
        val = "居家隔离";
        return val;
      }else if (val == 5) {
        val = "居家监测";
        return val;
      } else if (val == 9) {
        val = "其他";
        return val;
      }
    },

  },
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  components: {
    residentInformation,
  },
  created() {
    this.cardTypeDict = constants.TYPE_CERTIFICATE;
    this.queryTask()
    this.getAllIsolationOrgList(); //获取所有隔离酒店机构列表
    this.getBusinessOrgList();//获取机构居家隔离业务类型为是的(根据权限查询)
    this.homeOrgList();//对应的居家机构
  },
  mounted() {
  },
  methods: {
    // 切换radio按钮清空选项
    trasHome(){
        this.homeitem = "";
        this.ruleForm.transferDestination = "";
        this.isolationOrgItem = ""
    },
    homeOrgChange(orgCode){
      let dir = this.atHomeList.find((item) => item.orgCode === orgCode);
      this.ruleForm.transferDestination = dir.orgName;
      this.ruleForm.transferDestinationCode = dir.orgCode;
    },
    isolationOrgChange(orgCode){
      let dir = this.isolationOrg.find((item) => item.orgCode === orgCode);
      this.ruleForm.transferDestination = dir.orgName;
      this.ruleForm.transferDestinationCode = dir.orgCode;
    },
    // 获取机构居家隔离业务类型为是的
    async getBusinessOrgList() {
      let params = {
        homeIsolation: 1,
        orgCode: this.userdoctor.orgCode
      }
      const { data } = await getBusinessOrgList({params});
      if (data.code == '200') {
        this.xqsqsjoptionscx = data.data;
      }
    },
    // 根据业务状态去查询机构
    async homeOrgList() {
      let params = { homeIsolation: 1 }
      let { data } = await getBusinessOrgList({ params });
      this.atHomeList = data.data;
    },
    // 获取所有隔离酒店机构列表
    async getAllIsolationOrgList(){
        let res = await getAllIsolationOrgList();
        this.isolationOrg = res.data.data;
    },
    async distribute02(row) {
      // 清空选项
        this.homeitem = "";
        this.ruleForm.transferType = '';
        this.ruleForm.transferDestination = "";
        this.isolationOrgItem = "";
        this.ruleForm.transferRemark = '';
      // 查询提示语
      let params = {
            id:row.id,
            source: "2"
        }
        let res = await reliveControlTips ({params});
        this.tipsList = res.data.data.tips; //获取到的提示语数组
        this.unselect = res.data.data.unselect; //不选中类型
        this.select = res.data.data.select
        if(this.select!==null){
          this.ruleForm.transferType = this.select
        }
        
      this.id = row.id;
      if (row.controlMethod == 1) {
        this.isjjjc = true
      } else {
        this.isjjjc = false
      }
      if (row.controlMethod == 2) {
        this.isjjgl = true
      } else {
        this.isjjgl = false
      }
      this.isjcgl = true
    },
    qxaaaa(row) {
      this.isShowDetail = true;
      this.$nextTick(() => {
        this.$refs.residentInformationRef.getSurveyPersonInfo(row);
      });
    },
    sqchange(v) {
      this.sxdata01 = v;
      for (let i in this.sqoptions) {
        if (this.sqoptions[i].orgCode == v) {
          this.communityName = this.sqoptions[i].orgName;
          this.communityOrgCode = this.sqoptions[i].orgCode;
          this.communityOrgId = this.sqoptions[i].id;
        }
      }

    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryTask();
    },
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.queryTask();
    },
    // 查询待处理任务
    async queryTask(type) {
      let params
      if (type == 'btn') {
        this.pageNumber = 1;
      }
      let a, b;
      if (this.timevalue == null) {
        this.timevalue = [];
      } else {
        if (this.timevalue[0]) {
          a = this.timevalue[0];
          b = this.timevalue[1];
        }
      }

      params = {
        ...this.cx,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      if (this.timetime && this.timetime.length == 2) {
        params.startTimeMin = this.timetime[0] + " 00:00:00";
        params.startTimeMax = this.timetime[1] + " 23:59:59";
      }
      const { data } = await gklist({ params })
      if (data.code == '200') {
        this.tableData02 = data.data.list;
        this.total = parseInt(data.data.totalRow);
        this.pageNumber = data.data.pageNumber
        this.pageSize = data.data.pageSize
      }
    },
    async relive() {
      let params = {
        ...this.ruleForm,
        id: this.id
      };
      try {
        let { data } = await relive(params);
        if (data.code == 200) {
          this.$message({
            message: "解除隔离成功！",
            type: "success"
          });
          this.queryTask()
        }
      } catch (error) { }

      this.isjcgl = false
    }
  }
}

</script>
<style lang='scss' scoped>
.content_page {
  display: flex;
  justify-content: flex-end;
}
</style>